










































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({name: 'Organisations'})
export default class extends Vue {
  public $store: any;
  public $q: any;
  public organisations: any = [];
  public filter: string = '';
  public pagination: any = {
    rowsPerPage: 10,
    rowsNumber: 0,
    sortBy: 'name',
    page: 1,
  };
  public showDisabled: boolean = false;
  public loading: boolean = true;
  get columns() {
    const data: any = [
      {
        name: 'actions',
        required: true,
        headerStyle: 'width: 50px',
      },
    ];
    if (this.showDisabled) {
      data.push({
        name: 'enabled',
        required: true,
        label: 'Actif',
        align: 'left',
        field: (row: any) => row.enabled ? '✓ ' : '',
        sortable: true,
        headerStyle: 'width: 50px',
      });
    }
    data.push({
      name: 'name',
      required: true,
      label: 'Nom',
      align: 'left',
      field: (row: any) => row.name,
      sortable: true,
      headerStyle: 'max-width: 100px',
    });
    data.push({
      name: 'housing_mode',
      required: true,
      label: 'Mode logement',
      align: 'left',
      field: (row: any) => row.enabled ? '✓ ' : '',
      sortable: true,
      headerStyle: 'width: 50px',
    });
    data.push({
      name: 'subscription',
      label: 'Abonnement',
      align: 'left',
    });
    return data;
  }

  public async created() {
    this.refreshDefault();
  }

  @Watch('showDisabled')
  private refreshDefault() {
    this.refresh(
      this.pagination.rowsPerPage,
      this.pagination.page,
      this.pagination.sortBy,
      this.pagination.descending,
      null);
  }

  private refresh(limit: number, page: number, sortBy: any, descending: boolean, filter: any) {
    this.loading = true;
    this.$store.dispatch(
      'loadOrganisations', {
      limit,
      page: page - 1,
      filter,
      sort_by: sortBy,
      descending,
      show_disabled: this.showDisabled,
    }).then((response: any) => {
      this.organisations = response.organisations;
      this.pagination.rowsNumber = response.count;
      this.pagination.page = page;
      this.pagination.sortBy = response.sort_by;
      this.pagination.descending = response.descending;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private onRequest(props: any) {
    this.pagination.rowsPerPage = props.pagination.rowsPerPage;
    this.refresh(
      props.pagination.rowsPerPage,
      props.pagination.page,
      props.pagination.sortBy,
      props.pagination.descending,
      props.filter,
    );
  }

  private activateEntry(entryId: number) {
    this.$store.dispatch('activateOrganisation', {
      organisationId: entryId,
    }).then((response: any) => {
      this.refreshDefault();
    }).catch((error: Error) => {
      this.globalError(error);
    });
  }

  private deactivateEntry(entryId: number) {
    this.$store.dispatch('deactivateOrganisation', {
      organisationId: entryId,
    }).then((response: any) => {
      this.refreshDefault();
    }).catch((error: Error) => {
      this.globalError(error);
    });
  }

  private takeOverEntry(entryId: number) {
    this.$store.dispatch('takeOverOrganisation', {
      organisationId: entryId,
    }).then((response: any) => {
      this.$router.push('/');
      this.$router.go(0);
    }).catch((error: Error) => {
      this.globalError(error);
    });
  }

  private onSelect(entry: any) {
    //
  }

}
