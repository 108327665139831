import { render, staticRenderFns } from "./Organisations.vue?vue&type=template&id=469e1ee4&scoped=true&"
import script from "./Organisations.vue?vue&type=script&lang=ts&"
export * from "./Organisations.vue?vue&type=script&lang=ts&"
import style0 from "./Organisations.vue?vue&type=style&index=0&id=469e1ee4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469e1ee4",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QInput,QIcon,QCheckbox,QBtn,QTr,QTd,QPopupProxy,QBanner,QList,QItem,QItemSection});qInstall(component, 'directives', {ClosePopup});
